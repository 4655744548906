<template>
  <div class="vestDetail">
    <BaseHeaderBar title="Detail" :showCustomerService="false" :showBackButton="true" color="#333" bgColor="transparent" />
    <div class="content">
      <div>
        <p>Diagram klasifikasi</p>
        <img :src="detail.categoryImages" />
      </div>
      <div>
        <p>Nama pengeluaran</p>
        <p>{{ detail.billName }}</p>
      </div>
      <div>
        <p>jumlah pembayaran</p>
        <p>{{ detail.billAmount }}</p>
      </div>
      <div>
        <p>Tanggal akuntansi</p>
        <p>{{ detail.billTimes }}</p>
      </div>
      <div>
        <p>Jenis akuntansi</p>
        <p>{{ billTypeMap[detail.billType] }}</p>
      </div>
      <div>
        <p>Tempat</p>
        <p>{{ detail.recordLocation }}</p>
      </div>
      <div>
        <p>Komentar</p>
        <p>{{ detail.remarks }}</p>
      </div>
    </div>
    <button type="button" class="delete-btn" @click="deleteDetail">menghapus</button>
    <van-popup v-model="visible" close-on-click-overlay class="my-popup">
      <h3 class="title">petunjuk</h3>
      <p class="message">Apakah Anda yakin ingin menghapus pesan ini?</p>
      <div class="footer-btn">
        <button type="button" class="cancel-btn" @click="onCloseDialog()">Membatalkan</button>
        <button type="button" class="ok-btn" @click="onOk">Tentu</button>
      </div>
    </van-popup>
  </div>
</template>

<script>
import Vue from 'vue'
import { Popup, Toast } from 'vant'
import request from '@/utils/request'
import dialogVisible from '@/mixins/dialogVisible'
import webviewBack from '@/mixins/webviewBack'

Vue.use(Toast)
Vue.use(Popup)

const billTypeMap = {
  1: 'pengeluaran',
  2: 'penghasilan'
}

export default {
  name: 'VestDetail',
  mixins: [webviewBack, dialogVisible],
  data () {
    return {
      detail: {},
      billTypeMap
    }
  },
  created () {
    this.detail = this.$route.query
  },
  methods: {
    deleteDetail () {
      this.visible = true
    },
    onOk () {
      const postData = {
        billId: this.detail.billId
      }
      request.post('billingDelete', postData).then(() => {
        Toast('berhasil dihapus')
        this.$router.back()
      })
    }
  }
}
</script>

<style scoped lang="less">
.vestDetail {
  min-height: 100vh;
  background-color: #F8F8F8;
}

.content {
  margin: 33px 30px 50px;
  padding-left: 31px;
  padding-right: 35px;
  border-radius: 20px;
  background-color: #FFF;

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:first-child {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    &:nth-child(n+2) {
      height: 88px;
      line-height: 88px;
      border-top: 1px solid #EEE;
    }

    img {
      width: 160px;
      height: 160px;
      border-radius: 20px;
    }

    p {
      font-size: 32px;
      color: #333;

      &:first-of-type {
        font-weight: bold;
      }

      &:last-of-type {
        font-weight: 500;
      }
    }
  }
}

.delete-btn {
  width: 300px;
  height: 76px;
  line-height: 76px;
  border: 2px solid #5D7AFF;
  border-radius: 38px;
  margin-left: auto;
  margin-right: auto;
  font-size: 32px;
  font-weight: 500;
  color: #5D7AFF;
  text-align: center;
  background-color: #F8F8F8;
}

.my-popup {
  width: 560px;
  padding-top: 60px;
  padding-bottom: 60px;
  border-radius: 20px;
  text-align: center;
  background-color: #FFF;
}

.title {
  font-size: 36px;
  color: #333;
}

.message {
  margin-top: 30px;
  font-size: 32px;
  font-weight: 500;
  color: #333;
}

.footer-btn {
  display: flex;
  margin-left: 45px;
  margin-right: 45px;
  margin-top: 39px;

  button {
    flex: 1;
    height: 68px;
    line-height: 68px;
    font-size: 32px;
    border-radius: 34px;
  }

  .cancel-btn {
    border: 1px solid #5D7AFF;
    font-weight: 500;
    color: #5D7AFF;
    background-color: #FFF;
  }

  .ok-btn {
    margin-left: 30px;
    font-weight: bold;
    color: #FFF;
    background-color: #5D7AFF;
  }
}
</style>
